<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="中间商名称" prop="middleman">
          <el-input v-model="params.middleman" />
        </el-form-item>

        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="params.linkMan" />
        </el-form-item>

        <el-form-item label="联系方式" prop="linkPhone">
          <el-input v-model="params.linkPhone" />
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model="params.status" clearable>
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button v-auth="'middleman:add'" type="primary" @click="addSupplier"
        >添加中间商</el-button
      >
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="中间商名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkMan"
          label="联系人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkPhone"
          label="联系电话"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="关联供应商">
          <template slot-scope="{ row }">
            <el-button @click="checkHandler(row)" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          show-overflow-tooltip
          label="是否为服务商"
        >
          <template slot-scope="{ row }">
            <span>{{ row.isServiceProvider == 0 ? '否' : '是' }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="状态">
          <template slot-scope="{ row }">
            <span :style="{ color: row.status == 1 ? 'green' : 'red' }">{{
              row.status == 1 ? '启用' : '停用'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'middleman:update'"
              @click="editHandler(row)"
              type="text"
              >编辑</el-button
            >
            <el-button
              v-auth="
                `${row.status == 1 ? 'middleman:stop' : 'middleman:open'}`
              "
              @click="operate(row)"
              type="text"
              >{{ row.status == 1 ? '停用' : '启用' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      @getData="getData(true)"
      v-if="addVisible"
      :visible.sync="addVisible"
      :currentItem="currentItem"
    />
    <CheckModal
      v-if="checkVisible"
      :visible.sync="checkVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
import CheckModal from './components/CheckModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: null,
      addVisible: false,
      checkVisible: false,
      tableData: [],
      statusList: [
        {
          label: '启用',
          value: 1
        },
        {
          label: '停用',
          value: 2
        }
      ],
      params: {
        middleName: '',
        linkMan: '',
        linkPhone: '',
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    AddModal,
    CheckModal
  },
  created() {
    this.getData()
  },
  methods: {
    checkHandler(row) {
      this.currentItem = row
      this.checkVisible = true
    },
    addSupplier() {
      this.currentItem = null
      this.addVisible = true
    },
    editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    },
    async operate(row) {
      try {
        await this.$confirm(
          `是否要${row.status == 1 ? '停用' : '启用'}?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.middleman.middlemanUpdate({
            id: row.id,
            status: row.status == 1 ? 2 : 1
          })
          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      try {
        const res = await this.$api.middleman.getList(sendData)

        const tableData = res.page.list.map(item => {
          return item
        })

        this.tableData = tableData
        this.totalCount = res.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
