<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="关联供应商"
    :visible.sync="show"
    width="600px"
  >
    <el-table
      v-loading="loading"
      :data="tableData"
      max-height="300"
      style="width: 100%"
      top="10vh"
    >
      <el-table-column show-overflow-tooltip prop="id" label="供应商ID">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="name" label="供应商名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="linkMan" label="关联人员">
      </el-table-column>
    </el-table>

    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      totalCount: 0,
      params: {
        limit: 20,
        page: 1
      },
      tableData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true

        if (query) {
          this.params.limit = 20
          this.params.page = 1
        }

        const params = { ...this.params }

        params.middlemanId = this.currentItem.id

        const r = await this.$api.middleman.pageSupplier(params)

        this.tableData = (r.page.list || []).map(item => {
          return item
        })
        this.totalCount = r.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
