<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="isCreated ? '新增中间商' : '编辑中间商'"
    :visible.sync="show"
    width="600px"
  >
    <el-form
      size="small"
      :model="form"
      :rules="rules"
      ref="form"
      label-width="140px"
    >
      <el-form-item label="中间商" prop="name">
        <el-input v-model="form.name" placeholder="请输入中间商"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="linkMan">
        <el-input v-model="form.linkMan" placeholder="请输入联系人"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="linkPhone">
        <el-input
          type="number"
          v-model="form.linkPhone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="登录密码" :prop="isCreated ? 'password' : ''">
        <el-input
          v-model="form.password"
          placeholder="请输入登录密码（系统默认为12345678）"
        ></el-input>
      </el-form-item>
      <el-form-item label="中间商地址" prop="storeAddress">
        <div>
          <el-cascader
            size="small"
            style="width: 100%"
            :options="options"
            v-model="form.storeAddress"
            :props="props"
            clearable
          />
        </div>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          v-model="form.address"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否为服务商">
        <el-radio-group
          v-model="form.isServiceProvider"
          @change="changeHandler"
        >
          <el-radio v-for="item in items" :key="item.label" :label="item.label">
            {{ item.title }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="渠道收款商户名称" prop="mchName">
        <el-input
          v-model="form.mchName"
          placeholder="请输入渠道收款商户名称"
        ></el-input>
      </el-form-item>

      <template v-if="form.isServiceProvider == 0">
        <el-form-item label="商户清分编号" prop="subMerchantList">
          <div
            v-for="(item, index) in form.subMerchantList"
            :key="index"
            class="flowCommonItem"
          >
            <div class="inputWrap">
              <el-input
                type="number"
                v-model="item.subMchId"
                placeholder="请输入商户清分编号"
              ></el-input>
              <el-input
                type="number"
                v-model="item.serviceProviderId"
                placeholder="关联服务商档案号"
              ></el-input>
            </div>
            <i
              v-if="index == 0"
              @click="
                form.subMerchantList.push({
                  subMchId: '',
                  serviceProviderId: ''
                })
              "
              class="el-icon-circle-plus-outline"
            ></i>

            <i
              v-else
              @click="form.subMerchantList.splice(index, 1)"
              class="el-icon-remove-outline"
            ></i>
          </div>
        </el-form-item>
      </template>

      <template v-else>
        <el-form-item label="服务商档案号" prop="serviceProviderId">
          <el-input
            type="number"
            v-model="form.serviceProviderId"
            placeholder="请输入服务商档案号"
          ></el-input>
        </el-form-item>
        <el-form-item label="并笔支付档案号" prop="onlineMchId">
          <el-input
            type="number"
            v-model="form.onlineMchId"
            placeholder="请输入并笔支付档案号"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户清分编号" prop="subMchId">
          <el-input
            type="number"
            v-model="form.subMchId"
            placeholder="请输入商户清分编号"
          ></el-input>
        </el-form-item>

        <el-form-item label="渠道收款商户私钥" prop="onlinePrivateKey">
          <el-input
            v-model="form.onlinePrivateKey"
            placeholder="请输入渠道收款商户私钥"
          ></el-input>
        </el-form-item>

        <el-form-item label="渠道收款商户公钥" prop="onlinePublicKey">
          <el-input
            v-model="form.onlinePublicKey"
            placeholder="请输入渠道收款商户公钥"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    const _this = this

    const rules = {
      name: [
        {
          required: true,
          message: '请输入中间商'
        }
      ],
      linkMan: [
        {
          required: true,
          message: '请输入联系人'
        }
      ],
      linkPhone: [
        {
          required: true,
          message: '请输入联系电话',
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入联系电话'))
            } else {
              if (!this.$utils.isPhone(value)) {
                return callback(new Error('请输入正确的手机号格式'))
              } else {
                callback()
              }
            }
          }
        }
      ],
      storeAddress: [
        {
          required: true,
          trigger: 'change',
          message: '请选择中间商地址'
        }
      ],
      address: [
        {
          required: true,
          message: '请输入详细地址'
        }
      ],
      mchName: [
        {
          required: true,
          message: '请输入渠道收款商户名称'
        }
      ],
      subMerchantList: [
        {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (!value[0].serviceProviderId) {
              callback(new Error('请完善商户清分编号'))
            } else {
              if (!value[0].subMchId) {
                callback(new Error('请完善商户清分编号'))
              } else {
                callback()
              }
            }
          }
        }
      ],
      serviceProviderId: [
        {
          required: true,
          message: '请输入服务商档案号'
        }
      ],
      onlineMchId: [
        {
          required: true,
          message: '请输入并笔支付档案号'
        }
      ],
      onlinePrivateKey: [
        {
          required: true,
          message: '请输入渠道收款商户私钥'
        }
      ],
      onlinePublicKey: [
        {
          required: true,
          message: '请输入渠道收款商户公钥'
        }
      ],
      subMchId: [
        {
          required: true,
          message: '请输入商户清分编号'
        }
      ],

      password: [
        {
          required: true,
          message: '请输入登录密码'
        }
      ]
    }

    return {
      rules,
      loading: false,
      codeTimer: null,
      firstIn: true,
      numberTxt: '',
      options: [],
      items: [
        {
          title: '是',
          label: '1'
        },
        {
          title: '否',
          label: '0'
        }
      ],

      form: {
        storeAddress: [],
        subMerchantList: [
          {
            subMchId: '',
            serviceProviderId: ''
          }
        ],
        name: '',
        linkMan: '',
        linkPhone: '',
        address: '',
        address: '',
        mchName: '',
        subMchId: '',
        serviceProviderId: '',
        onlineMchId: '',
        onlinePrivateKey: '',
        onlinePublicKey: '',
        isServiceProvider: '0'
      },
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          try {
            const { level, value } = node
            let r = ''
            if (level == 0) {
              r = await _this.$api.common.getProvince()
            } else if (level == 1) {
              r = await _this.$api.common.getCity(value)
            } else {
              r = await _this.$api.common.getRegion(value)
            }
            const nodes = r.list.map(item => ({
              value: item.id,
              label: item.name,
              leaf: level >= 2 && level < 3
            }))
            resolve(nodes)
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
  },
  async created() {
    if (!this.isCreated) {
      try {
        const res = await this.$api.middleman.getById(this.currentItem.id)
        if (res) {
          Object.keys(this.form).forEach(key => {
            if (key !== 'storeAddress' && key !== 'subMerchantList') {
              this.form[key] = res && res.middlemanVo[key]
            }
          })

          const payInfo = JSON.parse(res.middlemanVo.payInfo)

          this.form.mchName = payInfo ? payInfo.mch_name : ''

          if (res.middlemanVo.isServiceProvider == 1 && payInfo) {
            this.form.subMchId = payInfo.sub_mch_id
            this.form.serviceProviderId = payInfo.service_provider_id
            this.form.onlineMchId = payInfo.online_mch_id
            this.form.onlinePrivateKey = payInfo.online_private_key
            this.form.onlinePublicKey = payInfo.online_public_key
          }

          if (
            res.middlemanVo.subMerchantList &&
            res.middlemanVo.subMerchantList.length
          ) {
            this.form.subMerchantList = res.middlemanVo.subMerchantList
          } else {
            this.form.subMerchantList = [
              {
                subMchId: '',
                serviceProviderId: ''
              }
            ]
          }

          this.form.storeAddress = [
            this.currentItem.provinceId,
            this.currentItem.cityId,
            this.currentItem.regionId
          ]
        }
      } catch (e) {
        console.log(e)
      }
    }
  },

  methods: {
    changeHandler() {
      this.$refs.form.clearValidate()

      this.form.subMchId = ''
      this.form.serviceProviderId = ''
      this.form.onlineMchId = ''
      this.form.onlinePrivateKey = ''
      this.form.onlinePublicKey = ''
      this.form.subMerchantList = [
        {
          subMchId: '',
          serviceProviderId: ''
        }
      ]
    },
    formSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          let payInfo = {}
          const {
            storeAddress,
            subMerchantList,
            subMchId,
            serviceProviderId,
            onlineMchId,
            mchName,
            onlinePrivateKey,
            onlinePublicKey,
            ...sendData
          } = { ...this.form }

          if (!this.isCreated) {
            sendData.id = this.currentItem.id
          }

          if (this.form.storeAddress && this.form.storeAddress.length) {
            sendData.provinceId = this.form.storeAddress[0]
            sendData.cityId = this.form.storeAddress[1] || ''
            sendData.regionId = this.form.storeAddress[2] || ''
          }

          if (sendData.isServiceProvider == 0) {
            sendData.subMerchantList = subMerchantList
            payInfo.mch_name = mchName
          } else {
            payInfo.mch_name = mchName
            payInfo.sub_mch_id = subMchId
            payInfo.online_mch_id = onlineMchId
            payInfo.service_provider_id = serviceProviderId
            payInfo.online_private_key = onlinePrivateKey
            payInfo.online_public_key = onlinePublicKey
          }

          sendData.payInfo = JSON.stringify(payInfo)

          sendData.status = 1

          try {
            this.isCreated
              ? await this.$api.middleman.middlemanSave(sendData)
              : await this.$api.middleman.middlemanUpdate(sendData)
            this.$message.success('操作成功')
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  margin-top: 8vh !important;
}

.flowCommonItem {
  color: #606266;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  i {
    font-size: 25px;
    margin-left: 4px;
    cursor: pointer;
  }
}

.inputWrap {
  ::v-deep .el-input {
    width: 150px !important;
    padding: 0 4px;
  }
}
</style>
